import { getCheckoutAmplitudeABTest } from '@/lib/amplitude-analytics';

const defaultCheckout = getCheckoutAmplitudeABTest();

export default function CheckoutSectionABTest({
  checkout,
}: {
  checkout?: React.ReactNode;
}) {
  return <>{defaultCheckout === 'checkout-old' && checkout}</>;
}
